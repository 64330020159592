<template>
  <div id="lottoset-setting" class="container-fluid">
    <div class="page-header mb-1">
      <strong>ตั้งค่าหวยชุด</strong>
    </div>

    <div v-if="roundId" class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบด้านบน)</small>
        </h5>
      </div>
      <div class="card-body">
        <SetsCloseTime :data="data" @reload="loadRoundData" />
        <!-- <LottosetPrizeTable :data="data" @reload="loadRoundData" /> -->
        <SetsReceiveTable class="mt-4" :data="data" @reload="loadRoundData" />
        <SetsReceiveNumbers class="mt-5" :data="data" :rateId="rateId" @reload="loadRoundData" />
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"

import cAlert from '@/helpers/alert'
import moment from '@/helpers/moment'

import SetsCloseTime from './components/SetsCloseTime'
// import LottosetPrizeTable from './components/LottosetPrizeTable'
import SetsReceiveTable from './components/SetsReceiveTable'
import SetsReceiveNumbers from './components/SetsReceiveNumbers'

export default {
  name: 'LottosetSetting',
  components: {
    SetsCloseTime,
    // LottosetPrizeTable,
    SetsReceiveTable,
    SetsReceiveNumbers
  },
  data() {
    return {
      data: null,
      isProcess: false,
      rateId: null
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    accountRole() {
      return this.$store.state.userProfile.role
    }
  },
  watch: {
    roundId() {
      this.loadRoundData()
    }
  },
  methods: {
    loadRoundData() {
      if(!this.roundId)
        return

      RoundService.getSetsRoundSetting(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data

          if(response?.data?.market?.marketType === 'single') {
            this.$router.push({name: 'RoundSetting'})
          }else{
            this.rateId = this.data?.rates?.[0]?._id
          }
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.loadRoundData()
    // if(this.accountRole === 'Company') {
    //   this.loadRoundData()
    // }else{
    //   this.$router.push({name: 'MarketReceive'})
    // }
  }
}
</script>
