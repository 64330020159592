<template>
  <div :ref="refDiv" :id="refDiv" v-if="isCompany || items.length" class="card border-pp mb-0">
    <div class="card-header p-1 alert-pp">{{ bet.text }}</div>
    <div class="card-body p-0">

      <div v-if="isCompany" class="p-1">
        <b-input-group size="md" v-if="permSetting">
          <b-form-input
            :id="refDiv+'-number'"
            type="text"
            placeholder="เพิ่มหมายเลข"
            :maxlength="maxlength"
            v-on:keydown="keynumber"
            v-model="input.number"
          ></b-form-input>
          <b-form-input
            :id="refDiv+'-amount'"
            type="text"
            :number="true"
            placeholder="จำนวนชุด"
            v-on:keydown="keynumber"
            v-model="input.amount"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="info" @click="saveReceiveNumber"><i class="fas fa-plus"></i></b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button variant="danger" @click="deleteReceiveNumber"><i class="fas fa-trash-alt"></i></b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="text-right">
          <small class="text-danger">(0 = ไม่รับของ)</small>
        </div>
      </div>

      <div v-if="items.length">
        <table class="table table-sm table-hover mb-0">
          <thead>
            <tr>
              <td v-if="isCompany" width="40">เลือก</td>
              <td width="35%" class="text-center">หมายเลข</td>
              <td class="text-center">จำนวนรับ</td>
              <td v-if="isCompany" class="text-center" width="50">ลบ</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items">
              <td v-if="isCompany">
                <b-form-checkbox
                  :disabled="!permSetting"
                  v-model="item.checked"
                  :value="true"
                  :unchecked-value="false"
                />
              </td>
              <td class="text-center text-primary">{{ item.number }}</td>
              <td class="text-center">{{ item.amount | amountFormat(0, '0') }}</td>
              <td v-if="isCompany" class="text-right"><button class="btn btn-sm btn-outline-danger py-0" @click="deleteNumbers([item.number])"><i class="fas fa-trash-alt"></i></button></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import RoundService from "@/services/RoundService"
import cAlert from '@/helpers/alert'
import _ from 'lodash'
import SetsHelper from '@/helpers/sets'

export default {
  name: 'SetsReceiveNumber',
  props: ['data', 'rateId', 'bet'],
  data() {
    return {
      input: {
        number: '',
        amount: ''
      }
    }
  },
  computed: {
    isCompany() {
      return this.$store.state.userProfile.role === 'Company'
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.RoundReceive === 'w'
    },
    refDiv() {
      return `sets-box-${this.bet.code}`
    },
    maxlength() {
      return {
        fiveNumber: 5,
        fourNumber: 4,
        threeNumber: 3,
        fourNumberTode: 4,
        threeNumberTode: 3,
        twoNumberPrefix: 2,
        twoNumberSuffix: 2
      }[this.bet.code]
    },
    roundId() {
      return this.data?.round?._id
    },
    items() {
      const Objs = this.data?.settings?.[this.rateId]?.numbers?.[this.bet.code] ?? {}
      const numArray = Object.keys(Objs).map((num)=>{
        return {
          checked: false,
          number: num,
          amount: Objs[num]
        }
      })

      return _.sortBy(numArray, (n)=>{
        return parseInt(n.number)
      })
    }
  },
  methods: {
    keynumber(event) {
      if(['Enter'].includes(event.key)) {
        event.preventDefault()

        if(this.input.amount==='') {
          document.getElementById(this.refDiv+'-amount').focus()
        }else{
          this.saveReceiveNumber()
        }
      }else
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'].includes(event.key)) {
        event.preventDefault()
      }
    },
    saveReceiveNumber() {

      if(this.input.number==='' || this.input.amount==='') {
        return Swal.fire({
          text: 'กรุณากรอกข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      if(this.input.number.toString().length !== this.maxlength) {
        return Swal.fire({
          text: `กรุณากรอกตัวเลข ${this.maxlength} ตัว`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      let groupNumbers = [this.input.number]
      if(this.bet.code === 'threeNumberTode') {
        /**
         * 3 ตัวโต๊ด
         */
        groupNumbers = SetsHelper.gThreeNumberTode(this.input.number) // _.uniqBy(this.gThreeNumberTode(this.input.number))
      }else
      if(this.bet.code === 'fourNumberTode') {
        /**
         * 4 ตัวโต๊ด
         */
        groupNumbers = SetsHelper.gFourNumberTode(this.input.number) //_.uniqBy(this.gFourNumberTode(this.input.number))
      }

      RoundService.saveSetsReceiveNumber({
        numbers: groupNumbers,
        amount: this.input.amount,
        roundId: this.roundId,
        rateId: this.rateId,
        code: this.bet.code
      })
      .then((response)=>{
        if(response.success) {
          this.input.number = ''
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เพิ่มข้อมูลเสร็จเรียบร้อย',
          })
          this.$emit('reload')
          document.getElementById(this.refDiv+'-number').focus()
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'เพิ่มข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    deleteNumbers(numbers=[]) {
      if(!numbers.length) {
        return Swal.fire({
          text: 'กรุณาเลือกข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      Swal.fire({
        title: 'ยืนยันลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {

          let groupNumbers = numbers
          if(this.bet.code === 'threeNumberTode') {
            /**
             * 3 ตัวโต๊ด
             */
            const allNumbers = numbers.reduce((ns, n)=>{
              return ns.concat(SetsHelper.gThreeNumberTode(n))
            }, [])

            groupNumbers = _.uniqBy(allNumbers)
          }else
          if(this.bet.code === 'fourNumberTode') {
            /**
             * 4 ตัวโต๊ด
             */
            const allNumbers = numbers.reduce((ns, n)=>{
              return ns.concat(SetsHelper.gFourNumberTode(n))
            }, [])

            groupNumbers = _.uniqBy(allNumbers)
          }

          RoundService.deleteSetsNumbers({
            numbers: groupNumbers,
            roundId: this.roundId,
            rateId: this.rateId,
            code: this.bet.code
          })
          .then((response)=>{
            if(response.success) {
              this.$emit('reload')
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            cAlert({
              title: 'ผิดพลาด!',
              text: 'ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    deleteReceiveNumber() {
      const numbers = this.items.filter((item)=>{
        return item.checked
      })
      .map((item)=>{
        return item.number
      })

      if(numbers.length) {
        this.deleteNumbers(numbers)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      td {
        font-size: 80%;
      }
    }
  }

  tbody, tfoot {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
